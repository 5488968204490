<template>
  <div class="modal topup-modal">
    <div
      class="overlay"
      @click="$parent.closePayoutModal"
    />
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img
              src="./../assets/close.svg"
              class="close"
              @click="$parent.closePayoutModal"
            >
            <div class="form-fields">
              <ValidationObserver
                ref="form"
                v-slot="{ handleSubmit, touched, valid }"
                slim
              >
                <div class="form-fields-wrapper">
                  <div class="cart-up">
                    <div class="cart-top">
                      <div class="title">
                        <b>{{ $t('WITHDRAW') }}</b>
                      </div>
                    </div>
                    <div
                      class="topup-form"
                    >
                      <!--                    <div class="desc">-->
                      <!--                      {{ $t('PAYMENT METHOD:') }}-->
                      <!--                    </div>-->
                      <!--                    <div class="desc">-->
                      <!--                      {{ $t('CREDIT CARD') }}-->
                      <!--                    </div>-->


                      <template v-if="step === 2">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          vid="amount"
                          name="amount"
                          slim
                        >
                          <label class="input-container">
                            <div class="desc">
                              {{ $t('Amount') }}
                            </div>
                            <input
                              v-model="amount"
                              type="text"
                              required
                              :placeholder="$t('Amount')"
                            >
                            <span
                              v-if="errors && errors.length"
                              class="error"
                            >{{ errors[0] }}
                            </span>
                          </label>
                        </ValidationProvider>
                      </template>

                      <template v-if="step === 3">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :rules="{required: true, regex: /^\d{4}\s\d{4}\s\d{4}\s\d{4}$/}"
                          vid="pan"
                          name="Card number"
                          slim
                        >
                          <label class="input-container">
                            <div class="desc">
                              {{ $t('Card number') }}
                            </div>
                            <input
                              v-model="formattedPan"
                              type="text"
                              required
                              :placeholder="$t('Card number')"
                              @input="formattedPan = formatCardNumber($event.target.value)"
                            >
                            <span
                              v-if="errors && errors.length"
                              class="error"
                            >{{ errors[0] }}
                            </span>
                          </label>
                        </ValidationProvider>
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          vid="first_name"
                          name="Name"
                          slim
                        >
                          <label class="input-container">
                            <div class="desc">
                              {{ $t('Name') }}
                            </div>
                            <input
                              v-model="first_name"
                              type="text"
                              required
                              :placeholder="$t('Name')"
                            >
                            <span
                              v-if="errors && errors.length"
                              class="error"
                            >{{ errors[0] }}
                            </span>
                          </label>
                        </ValidationProvider>
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          vid="last_name"
                          name="Surname"
                          slim
                        >
                          <label class="input-container">
                            <div class="desc">
                              {{ $t('Surname') }}
                            </div>
                            <input
                              v-model="last_name"
                              type="text"
                              required
                              :placeholder="$t('Surname')"
                            >
                            <span
                              v-if="errors && errors.length"
                              class="error"
                            >{{ errors[0] }}
                            </span>
                          </label>
                        </ValidationProvider>

                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          vid="selectedCountry"
                          name="Country"
                          slim
                        >
                          <label class="input-container">
                            <div class="desc">
                              {{ $t('Country') }}
                            </div>
                            <select
                              v-model="selectedCountry"
                              required
                            >
                              <option
                                value=""
                                disabled
                                selected
                              >
                                {{ $t('Countries') }}
                              </option>
                              <option
                                v-for="item in countries"
                                :key="item.id"
                                :value="item"
                              >
                                {{ $t(item.title) }}
                              </option>
                            </select>
                            <span
                              v-if="errors && errors.length"
                              class="error"
                            >{{ errors[0] }}
                            </span>
                          </label>
                        </ValidationProvider>

                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          vid="city"
                          name="City"
                          slim
                        >
                          <label class="input-container">
                            <div class="desc">
                              {{ $t('City') }}
                            </div>
                            <input
                              v-model="city"
                              type="text"
                              :placeholder="$t('City')"
                              required
                            >
                            <span
                              v-if="errors && errors.length"
                              class="error"
                            >{{ errors[0] }}
                            </span>
                          </label>
                        </ValidationProvider>

                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          vid="address"
                          name="Address"
                          slim
                        >
                          <label class="input-container">
                            <div class="desc">
                              {{ $t('Address') }}
                            </div>
                            <input
                              v-model="address"
                              type="text"
                              :placeholder="$t('Address')"
                              required
                            >
                            <span
                              v-if="errors && errors.length"
                              class="error"
                            >{{ errors[0] }}
                            </span>
                          </label>
                        </ValidationProvider>

                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                          vid="zip"
                          name="Post Code"
                          slim
                        >
                          <label class="input-container">
                            <div class="desc">
                              {{ $t('Post Code') }}
                            </div>
                            <input
                              v-model="zip"
                              type="text"
                              :placeholder="$t('Post Code')"
                              required
                            >
                            <span
                              v-if="errors && errors.length"
                              class="error"
                            >{{ errors[0] }}
                            </span>
                          </label>
                        </ValidationProvider>

                        <ValidationProvider
                          :rules="{required:{allowFalse:false}}"
                          vid="terms"
                          slim
                        >
                          <div class="cta-container">
                            <div class="chekbox-container">
                              <label class="chekbox-label">
                                <div class="chekbox-label-wrapper">
                                  <input
                                    v-model="terms"
                                    type="checkbox"
                                    name="terms"
                                  >
                                  <div class="checkbox" />
                                  <span class="title">{{ $t('I agree with') }} </span>
                                  <a
                                    class="title"
                                    @click="$parent.goToPage('priv acy')"
                                  > {{ $t('privacy policy') }}</a>
                                  <span class="title"> {{ $t('and') }} </span>
                                  <a
                                    class="title"
                                    @click="$parent.goToPage('terms')"
                                  > {{ $t('terms and conditions') }}</a>
                                </div>
                              </label>
                            </div>
                          </div>
                        </ValidationProvider>
                      </template>
                    </div>
                  </div>
                  <div class="cart-bottom">
                    <button
                      :disabled="!valid"
                      :class="['button blue', {disabled: !valid}]"
                      @click="proceed"
                    >
                      {{ $t('Withdraw') }}
                    </button>
                    <div class="desc">
                      {{ $parent.currency }} {{ amount }}
                    </div>
                  </div>
                  <transition name="slide">
                    <div
                      v-if="$parent.error"
                      class="error-desc desc red"
                    >
                      {{ $t($parent.error) }}
                    </div>
                  </transition>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {ValidationObserver, ValidationProvider} from "vee-validate";
import axios from "@/axios";

export default {
  name: 'PayoutModal',
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: [],
  data: function () {
    return {
      step: 2,
      amount: 5,
      method: 'card',
      pan: '',
      formattedPan: '',    // Formatted value
      first_name: '',
      last_name: '',
      phone: '',
      country: '',
      selectedCountry: '',
      countryId: '',
      city: '',
      address: '',
      zip: '',
      countryOptions: [],
      countryOptionsId: [],
      countries: [],
      terms: ''
    }
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (
          this.terms
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  mounted() {
    this.getCounties();
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
          this.phone = res.data.phone;
          this.first_name = res.data.name;
          this.last_name = res.data.surname;
          this.countryId = res.data.country;
          this.city = res.data.city;
          this.address = res.data.address;
          this.zip = res.data.zip;

          if (this.countries && this.countries.length && this.countryId) {
            this.setCountry();
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          }
        })
  },
  methods: {
    async proceed() {
      if (this.step === 1 || this.step === 2) {
        this.step = this.step + 1;
      } else {
       try {
         const data = {
           method: this.method,
           amount: this.amount,
           first_name: this.first_name,
           last_name: this.last_name,
           country_id: this.selectedCountry ? this.selectedCountry.id : null,
           city: this.city,
           address: this.address,
           zip: this.zip
         };

         if (this.method === 'card') {
           data['pan'] = this.formattedPan.replace(/\s+/g, '')
         }

         await axios.post(`/payouts`, data)

         this.$parent.closePayoutModal();
       } catch (error) {
         if (error.response) {
           if (error.response.data.errors) {
             this.$refs.form.setErrors(error.response.data.errors);
           } else if (error.response.data.status === 'ERROR' && error.response.data.message) {
             this.$refs.form.setErrors({
               'zip': [error.response.data.message]
             });
           }
         }
       }
      }
    },
    setCountry() {
      let mapped = this.countries.map(({
                                         id,
                                         title
                                       }) => ({
        id,
        title
      }));
      this.countries = mapped;
      this.selectedCountry = this.countries.find(item => item.id == this.countryId);

      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == this.selectedCountry.name) {
          this.countryId = this.countryOptionsId[i];
        }
      }

    },
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
          .then((res) => {
            let arr = res.data.payload;
            let newCountries = arr.map(function (item) {
              return item['title'];
            });
            let newCountriesId = arr.map(function (item) {
              return item['id'];
            });
            this.countryOptionsId = newCountriesId;
            this.countryOptions = newCountries;
            this.countries = arr;
            let mapped = this.countries.map(({
                                               id,
                                               title
                                             }) => ({
              id,
              title
            }));
            this.countries = mapped;

            if (this.countries && this.countries.length && this.countryId) {
              this.setCountry();
            }
          })
          .catch((res) => {
            if (res.response.status === 401) {
              this.$parent.openSignIn();
            }
          })
    },
    allowOnlyNumbers() {
      this.amount = this.amount.replace(/[^0-9.,]/g, '');

      // Split the input into integer and decimal parts
      const parts = this.amount.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.amount = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    formatCardNumber(value) {
      // Remove non-digit characters
      value = value.replace(/\D/g, '');

      // If the input length exceeds 16, truncate it
      if (value.length > 16) {
        return value.slice(0, 16).replace(/(\d{4})(?=\d)/g, '$1 '); // Keep only the first 16 digits
      }

      // Add spaces every 4 digits
      return value.replace(/(\d{4})(?=\d)/g, '$1 ');
    },
    send() {
      let data = {
        "name": this.first_name,
        "surname": this.last_name,
        "phone": this.phone,
        "amount": this.amount,
        "country": this.selectedCountry.id,
        "city": this.city,
        "address": this.address,
        "postCode": this.zip
      }

      this.$emit('openOrderModal', data);
    }
  }
}
</script>